import React from "react";
import NodeCardActive from "../../node/NodeCardActive.js";
import NodeCardEnd from "../../node/NodeCardEnd.js";

export default function Node({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  isEndNode,
  isLastOnPath,
  language,
  links,
  nodeId,
  nodeTitle,
  onNext,
  onReset,
  options,
}) {
  if (isLastOnPath && !isEndNode) {
    return (
      <NodeCardActive
        children={children}
        disableDefaultOptionsButtons={disableDefaultOptionsButtons}
        extraContent={extraContent}
        language={language}
        links={links}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        onNext={onNext}
        onReset={onReset}
        options={options}
      />
    );
  } else if (isLastOnPath && isEndNode) {
    return (
      <NodeCardEnd
        children={children}
        language={language}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        onReset={onReset}
      />
    );
  } else {
    return null;
  }
}
