import React from "react";
import LayoutExamples from "../../../layouts/LayoutExamples";
import Seo from "../../../components/seo";
import { Row, Col, Divider } from "antd";
import ButtonsNavigation from "../../../components/ui/ButtonsNavigation.js";
import ChartSingle from "../../../components/chart/interactions/basic-single/Chart.js";
import ChartExamplesFirstLineCustomerSupport from "../../../client/examples/json/first-line-customer-support.json";
import { FullscreenOutlined } from "@ant-design/icons";

const ExampleChartSupport = () => (
  <LayoutExamples>
    <Seo
      title="Single Chart Type"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      align="middle"
      style={{ backgroundColor: "#f1f3f5", minHeight: "30vh" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <ChartSingle
          entry={ChartExamplesFirstLineCustomerSupport}
          firstNodeId="node1"
        />
      </Col>
    </Row>
    <Row
      justify="center"
      align="middle"
      style={{ backgroundColor: "#f1f3f5", paddingBottom: "48px" }}
    >
      <Col>
        <a
          href="/chart/examples/first-line-customer-support"
          target="_blank"
          rel="noopener norefferer"
        >
          View schematic chart (fullscreen) <FullscreenOutlined />
        </a>
      </Col>
    </Row>
    <Divider dashed style={{ margin: "0" }} />
    <Row
      justify="center"
      align="middle"
      style={{ backgroundColor: "#f8f9fa", padding: "48px 0" }}
    >
      <Col>
        <ButtonsNavigation
          linkPrevious="Previous example"
          linkPreviousTo="/examples/medical/example-licht-traumatisch-hoofd-hersenletsel-kinderen-tot-16-jaar"
        />
      </Col>
    </Row>
  </LayoutExamples>
);

export default ExampleChartSupport;
