import React from "react";

import Overview from "../../../client/lta-antistolling/Overview";
import SelectBloedRisicoTest from "../../../client/lta-antistolling/SelectBloedRisicoTest";
import TableTromboRisico from "../../../client/lta-antistolling/TableTromboRisico";

/* eslint-disable react/display-name */
export const ClientLtaComponents = {
  overview: () => <Overview />,
  ltaBloedRisico: () => <SelectBloedRisicoTest />,
  ltaTromboRisico: () => <TableTromboRisico />,
};
